.cyber-header {
    margin-bottom: 120px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    letter-spacing: 0.02em;

    .btn {
        margin-right: -90px;
        padding: 19px 27px;
        color: $white;
        border: 2px solid $white;
        box-sizing: border-box;
        border-radius: 42px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;

        &:hover {
            background-color: $white;
            color: #084654;
        }
    }
}

.cyber {
    padding: 48px 0 10px;
    background: linear-gradient(117.31deg, #084654 1.22%, #105862 45.97%, #105862 96.68%);
    color: $white;
    min-height: 100vh;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(../img/bg-dots.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .line {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        top: calc(50% - 8px);
        left: 0;
        transform: rotate(-90deg);

        &:before {
            position: absolute;
            content: '';
            width: 15vw;
            top: 50%;
            left: 150px;
            height: 1px;
            border: 1px solid rgba(150, 206, 189, 0.5);
        }

        &:after {
            position: absolute;
            content: '';
            width: 15vw;
            top: 50%;
            right: 150px;
            height: 1px;
            border: 1px solid rgba(150, 206, 189, 0.5);
        }
    }

    .text {
        padding-left: 20px;
        max-width: 750px;
    }

    h1 {
        font-family: $font-family-base;
        font-weight: 300;
        font-size: 65px;
        line-height: 79px;
        letter-spacing: 0.02em;
    }

    .slog {
        top: -87px;
        left: -40px;
        font-weight: 800;
        font-size: 175px;
        line-height: 175px;
        letter-spacing: 0.02em;
        opacity: 0.1;
    }

    p {
        font-weight: 300;
        letter-spacing: 0.02em;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 7px;
        font-size: 15px;
        line-height: 34px;
        letter-spacing: 0.02em;

        svg {
            margin-right: 27px;
        }
    }

    .btn-grad {
        padding: 22px 40px 19px;
        position: relative;
        font-weight: 700;
        border-radius: 42px;
        border: 0;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        box-shadow: 0px 1px 30px rgba(150, 206, 189, 0.7);
        background: linear-gradient(91.97deg, #96CEBD 2.8%, #739FAC 97.3%);

        span {
            position: relative;
            z-index: 2;
            color: #105862;
        }

        &:after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 42px;
            z-index: 0;
            transition: opacity .3s linear;
            opacity: 0;
            background: linear-gradient(91.97deg, #739FAC 2.8%, #96CEBD 97.3%);
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}

@media(max-width: 1700px) {
    .cyber-header .btn {
        margin-right: -50px;
    }

    .cyber .text {
        padding-left: 45px;
    }
}

@media(max-width: 1500px) {
    .cyber-header .btn {
        margin-right: 0;
    }

    .cyber .text {
        padding-left: 45px;
    }
}

@media(max-width: 1300px) {
    .cyber .line {
        display: none;
    }
}

@media(max-width: 1200px) {

    .cyber {
        .text {
            padding-left: 0px;
        }

        .img-wrap {
            margin-left: -50px;
        }
    }
}

@media(max-width: 767px) {
    .cyber {
        h1 {
            font-size: 45px;
            line-height: 59px;
        }

        .slog {
            font-size: 100px;
            line-height: 100px;
            left: 50%;
            transform: translateX(-50%);
            top: -40px;
        }

        ul {
            margin: auto;
        }

        .list-2 {
            li {
                margin-left: -10px;
            }
        }

        .img-wrap {
            margin-left: -20px;
        }

    }
}

@media(max-width: 400px) {
    .cyber {
        .img-wrap {
            margin-left: -10px;
            height: 300px;
        }
    }
}

@media(max-width: 350px) {
    .cyber {

        .list-2 {
            li {
                margin-left: 0;
            }
        }

    }
}
