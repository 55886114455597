header {
    padding: 33px 0;
    z-index: 20;

    a {
        color: $white;

        &:hover {
            color: $yellow;
        }
    }

    .communication {
        margin-bottom: 34px;
    }

    .go-form {
        margin-left: 60px;

        a {
            color: $yellow;
            font-weight: 800;
            letter-spacing: 0.02em;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;

        }
    }

    .social {
        margin-left: 60px;

        svg {
            path {
                transition: .3s;
            }

            &:hover {
                path {
                    fill: $yellow;
                }
            }
        }
    }

    .tel {
        a {
            letter-spacing: 0.03em;
            line-height: 20px;
        }
    }

    .col-3 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .col-9 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    nav {
        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.03em;
        }
    }
}

.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 300ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 99;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill: none;
    transition: stroke-dasharray 300ms, stroke-dashoffset 300ms;
    stroke: $yellow;
    stroke-width: 3;
    stroke-linecap: unset;
}


.ham4 .top {
    stroke-dasharray: 40 121;
}

.ham4 .middle {
    stroke-dasharray: 40 121;
}

.ham4 .bottom {
    stroke-dasharray: 40 121;
}

.ham4.active .top {
    stroke-dashoffset: -68px;
}

.ham4.active .bottom {
    stroke-dashoffset: -68px;
    stroke-dasharray: 40 121;
}

nav.mm-menu {
    opacity: 0;
    display: none;
}

nav.mm-menu.mm-menu_opened {
    opacity: 1;
    display: block;
}

header nav.fixed1 {
    top: -204px;
}

header nav.fixed {
    position: fixed !important;
    top: 0;
    width: 100%;
    left: 0;
    background-color: rgba(#005E6A, .98);
    transition: .6s;

    ul {
        padding: 30px 50px;
        max-width: 1200px;
        margin: auto;

    }
}

.mh-head.mh-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    -webkit-transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

header.fixed1 {}

#navbar {
    position: fixed !important;
    top: 0;
    width: 100%;
    left: 0;
    background-color: rgba(#005E6A, .98);

}

.mh-head.mh-sticky.mh-scrolledout {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

#navbar {
    opacity: 0;
    position: fixed;
    width: 100%;
    min-height: 72px;

    .logo {
        padding-left: 22px;
    }

    .ham {
        position: relative;
    }
    .wrapper-menu{
        right: 22px;
        top: 16px;
    }
}

@media(min-width: 992px) {
    #navbar {
        display: none !important;
    }
}

@media(max-width: 992px) {
    header {
        padding: 16px 0;

        .logo {
            svg {
                width: 64px;
                height: 71px;
            }
        }

        nav {
            display: none;
        }
    }
}

.mm-panels > .mm-panel {
    padding-left: 60px;
    padding-top: 79px;
}

.mm-navbar {
    display: none;
}

.mm-listitem {
    margin-bottom: 25px;

    a {
        color: $green !important;
        line-height: 21px;
        font-weight: 700;
        letter-spacing: 0.03em;
    }

    &:after {
        display: none;
    }
}

.tel.mm-listitem{
    a{
        padding: 12px 10px 12px 20px;
    }
}
.icon.mm-listitem{
    padding: 12px 30px 12px 20px;
    justify-content: space-between;
}

.wrapper-menu {
    position: absolute;
    z-index: 9999999;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
    cursor: pointer;
}

.wrapper-menu.open {
    transform: rotate(-45deg);
}

.line-menu {
    background-color: $yellow;
    border-radius: 5px;
    width: 100%;
    height: 5px;
}

.line-menu.half {
    width: 50%;
}

.line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.open .line-menu.start {
    transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
}

.open .line-menu.end {
    transform: rotate(-90deg) translateX(-3px);
}
